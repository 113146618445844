import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { DailySummaryGrouped, DailySummarySplitted } from '../../models';
import { DailySummaryService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class DailySummaryStoreEffects {
  constructor(
    private dataService: DailySummaryService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadGrouped$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadGroupedRequest),
      switchMap(({ properties, dateFrom, dateTo }) =>
        this.dataService.loadGrouped(properties, dateFrom, dateTo).pipe(
          map((response: IResponseSuccess<DailySummaryGrouped>) =>
            fromActions.loadGroupedSuccess({
              dailySummary: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadGroupedFailure(error));
          }),
        ),
      ),
    ),
  );

  loadSplitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSplittedRequest),
      switchMap(({ properties, dateFrom, dateTo }) =>
        this.dataService.loadSplitted(properties, dateFrom, dateTo).pipe(
          map((response: IResponseSuccess<DailySummarySplitted>) =>
            fromActions.loadSplittedSuccess({
              dailySummary: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadSplittedFailure(error));
          }),
        ),
      ),
    ),
  );

  exportGrouped$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.exportGrouped),
        map(({ properties, dateFrom, dateTo, columns, orientation }) =>
          this.dataService.exportGrouped(
            properties,
            dateFrom,
            dateTo,
            columns,
            orientation,
          ),
        ),
      ),
    { dispatch: false },
  );

  exportSplitted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.exportSplitted),
        map(({ properties, dateFrom, dateTo, columns, orientation }) =>
          this.dataService.exportSplitted(
            properties,
            dateFrom,
            dateTo,
            columns,
            orientation,
          ),
        ),
      ),
    { dispatch: false },
  );
}
