import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadGroupedRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadGroupedSuccess, (state, { dailySummary }) => ({
    ...state,
    grouped: dailySummary,
    splitted: null,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadGroupedFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadSplittedRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSplittedSuccess, (state, { dailySummary }) => ({
    ...state,
    splitted: dailySummary,
    grouped: null,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadSplittedFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function dailySummaryReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
