import { createAction, props } from '@ngrx/store';

import {
  DailySummaryGrouped,
  DailySummarySplitted,
  PrintOrientation,
} from '../../models';

export const loadGroupedRequest = createAction(
  '[Daily Summary] Load Grouped Request',
  props<{
    properties: number[];
    dateFrom: string;
    dateTo: string;
  }>(),
);

export const loadGroupedSuccess = createAction(
  '[Daily Summary] Load Grouped Success',
  props<{ dailySummary: DailySummaryGrouped }>(),
);

export const loadGroupedFailure = createAction(
  '[Daily Summary] Load Grouped Failure',
  props<{ error: any }>(),
);

export const loadSplittedRequest = createAction(
  '[Daily Summary] Load Splitted Request',
  props<{
    properties: number[];
    dateFrom: string;
    dateTo: string;
  }>(),
);

export const loadSplittedSuccess = createAction(
  '[Daily Summary] Load Splitted Success',
  props<{ dailySummary: DailySummarySplitted }>(),
);

export const loadSplittedFailure = createAction(
  '[Daily Summary] Load Splitted Failure',
  props<{ error: any }>(),
);

export const exportGrouped = createAction(
  '[Daily Summary] Export Grouped',
  props<{
    properties: number[];
    dateFrom: string;
    dateTo: string;
    columns: string[];
    orientation: PrintOrientation;
  }>(),
);

export const exportSplitted = createAction(
  '[Daily Summary] Export Splitted',
  props<{
    properties: number[];
    dateFrom: string;
    dateTo: string;
    columns: string[];
    orientation: PrintOrientation;
  }>(),
);

export const resetState = createAction('[Daily Summary] Reset State');
