import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getGrouped = (state: State) => state.grouped;

export const getSplitted = (state: State) => state.splitted;

export const selectState = createFeatureSelector<State>('dailySummary');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectGrouped = createSelector(selectState, getGrouped);

export const selectSplitted = createSelector(selectState, getSplitted);
