import { DailySummaryGrouped, DailySummarySplitted } from '../../models';

export interface State {
  grouped: DailySummaryGrouped;
  splitted: DailySummarySplitted;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  grouped: null,
  splitted: null,
  isLoading: false,
  error: null,
};
